import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  checkEmail,
  confirmBusinessUser,
  createBusinessUserStore,
  createPassword,
  getBusinessUserDetails,
  getDiscountCode,
  getProductDetails,
} from "../store/postRequests/businessUser";

export enum EStoreKey {
  BUSINESS_USER = "business-user",
  EMAIL_CONFIRM = "confirm-email",
  BUSINESS_DETAILS = "business-details",
  PRODUCT_DETAILS = "product-details",
  DISCOUNT_CODE = "discount-code",
  CREATE_PASSWORD = "create-password",
  CHECK_EMAIL = "check-email",
}

export const useGetBusinessUserDetails = (userEmail: any) =>
  useQuery([EStoreKey.BUSINESS_DETAILS, userEmail], () =>
    getBusinessUserDetails(userEmail)
  );

export const useGetDiscountCode = (promoName: string | undefined) =>
  useQuery(
    [EStoreKey.DISCOUNT_CODE, promoName],
    () => getDiscountCode(promoName),
    {
      retry: false,
    }
  );

export const useGetProductDetails = () =>
  useQuery(EStoreKey.PRODUCT_DETAILS, getProductDetails);

export const useCreateBusinessUser = () => {
  const queryClient = useQueryClient();

  return useMutation(createBusinessUserStore, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.BUSINESS_USER);
    },
  });
};

export const useConfirmBusinessUser = () => {
  const queryClient = useQueryClient();

  return useMutation(confirmBusinessUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.EMAIL_CONFIRM);
    },
    retry: false,
  });
};

export const useCreatePassword = () => {
  const queryClient = useQueryClient();

  return useMutation(createPassword, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.CREATE_PASSWORD);
    },
  });
};
export const useCheckEmail = () => {
  const queryClient = useQueryClient();

  return useMutation(checkEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.CHECK_EMAIL);
    },
  });
};
