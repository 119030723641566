import { FC } from "react";

import UserSetupPage from "../UserSetup/UserSetupPage";
import LoginDetailsConfirmPage from "../LoginDetailsConfirm/LoginDetailsConfirmPage";
import { useGlobalContext } from "../../store/pageStore/pageStore";

const UserSection: FC = () => {
  const { page } = useGlobalContext();
  return (
    <>
      {page === "userDetails" && <UserSetupPage />}
      {page === "loginDetailsConfirm" && <LoginDetailsConfirmPage />}
    </>
  );
};

export default UserSection;
