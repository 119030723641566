import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getPaymentInfo, postPaymentIntent, postPaymentSession } from '../store/postRequests/paymentRequests';


export enum EStoreKey {
    PAYMENT_INTENT = 'payment-intent',
    PAYMENT_SESSION = 'payment-session',
    PAYMENT_INFO = 'payment-info'
  }

  export const useGetPaymentInfo = (
    userPayEmail: any,
    passwordMade: boolean
  ) => useQuery([EStoreKey.PAYMENT_INFO, userPayEmail],  ()  => getPaymentInfo(userPayEmail), {
    enabled: passwordMade === true,

  });

  // ----- Mutations ------ //

export const usePostPaymentIntent = () => {
    const queryClient = useQueryClient();
  
    return useMutation(postPaymentIntent, {
      onSuccess: () => {
        queryClient.invalidateQueries(EStoreKey.PAYMENT_INTENT);
      },
    });
  };

export const usePostPaymentSession = () => {
    const queryClient = useQueryClient();
  
    return useMutation(postPaymentSession, {
      onSuccess: () => {
        queryClient.invalidateQueries(EStoreKey.PAYMENT_SESSION);
      },
    });
  };
