import { FC, useState } from "react";
import { Button, Modal } from "antd";

import styles from "./DiscountErrorModal.module.scss";
import { useGlobalContext } from "../../../store/pageStore/pageStore";

const DiscountErrorModal: FC = () => {
  const { setDiscountFetch } = useGlobalContext();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleErrorCancel = () => {
    setIsOpen(false);
    setDiscountFetch(false);
  };
  const handleErrorCanelClick = () => {
    setIsOpen(false);
    setDiscountFetch(false);
  };

  return (
    <>
      <Modal
        title={<h3 className={styles.title}>Oops! Something Went Wrong!</h3>}
        open={isOpen}
        centered
        footer={null}
        onCancel={handleErrorCancel}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <section className={styles.modalContent_text}>
              <p>Discount code invalid! Please try another</p>
            </section>

            <div className={styles.modalFooter}>
              <Button
                style={{
                  minWidth: "110px",
                  fontSize: "0.9rem",
                  padding: "6px",
                }}
                onClick={handleErrorCanelClick}>
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DiscountErrorModal;
