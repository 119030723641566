import { FC } from "react";
import { TiTick } from "react-icons/ti";
import styles from "../ProgressBar.module.scss";

const Payment: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.circleCompletedGrayed}>
        <div className={styles.text}>Choose Plan</div>
        <span className={styles.circleCompleted_tick}>
          <TiTick size={35} />
        </span>
      </div>

      <div className={styles.circleCompletedGrayed}>
        <div className={styles.text}>Your Business</div>
        <span className={styles.circleCompleted_tick}>
          <TiTick size={35} />
        </span>
      </div>

      <div className={styles.circleCompletedGrayed}>
        <div className={styles.text}>User Details</div>
        <span className={styles.circleCompleted_tick}>
          <TiTick size={35} />
        </span>
      </div>

      <div className={styles.circleSelected}>
        <div className={styles.text}>Payment</div>
      </div>

      <div className={styles.circle}>
        <div className={styles.text}>Confirm</div>
      </div>
    </div>
  );
};

export default Payment;
