import { FC } from "react";
import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";
import styles from "../ProgressBar.module.scss";

const UserSetup: FC = () => {
  return (
    <div className={styles.container}>
      <Link to="/">
        <div className={styles.circleCompleted}>
          <div className={styles.text}>Choose Plan</div>
          <span className={styles.circleCompleted_tick}>
            <TiTick size={35} />
          </span>
        </div>
      </Link>

      <Link to="/business-details">
        <div className={styles.circleCompleted}>
          <div className={styles.text}>Your Business</div>
          <span className={styles.circleCompleted_tick}>
            <TiTick size={35} />
          </span>
        </div>
      </Link>

      <div className={styles.circleSelected}>
        <div className={styles.text}>User Details</div>
      </div>

      <div className={styles.circle}>
        <div className={styles.text}>
          <span>Payment</span>
        </div>
      </div>

      <div className={styles.circle}>
        <div className={styles.text}>Confirm</div>
      </div>
    </div>
  );
};

export default UserSetup;
