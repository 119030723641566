import { FC } from "react";
import LoginDetailsConfirm from "../../Components/LoginDetailsConfirm/LoginDetailsConfirm";
import EmailSent from "../../Components/ProgressBar/EmailSent/EmailSent";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

const LoginDetailsConfirmPage: FC = () => {
  return (
    <>
      {/* Progress Bar */}
      <EmailSent />
      {/* Progress Bar */}
      <SectionHeader>Login Details</SectionHeader>
      <LoginDetailsConfirm />
    </>
  );
};

export default LoginDetailsConfirmPage;
