import { FC } from "react";

import Finish from "../../Components/ProgressBar/Finish/Finish";
import styles from "./FreeCouponFinishPage.module.scss";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import { Button } from "antd";

const FreeCouponFinishPage: FC = () => {
  const devLink = "https://dev.ev.pebble.tech/";
  const liveLink = "https://ev.pebble.tech/app ";

  return (
    <>
      {/* Progress Bar */}
      <Finish />
      {/* Progress Bar */}

      <SectionHeader>
        <span className={styles.header}>Account Confirmed!</span>
      </SectionHeader>
      <p className={styles.title}>
        You can now login to your company dashboard and turbocharge your
        engagement!
      </p>

      <div className={styles.buttonContainer}>
        <a href={devLink} target="_blank" rel="noreferrer">
          <Button>Login To Dashboard!</Button>
        </a>
      </div>
    </>
  );
};

export default FreeCouponFinishPage;
