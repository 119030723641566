let DashboardAPI;
const env = process.env.TARGET_ENV;
if (env === "development") {
  DashboardAPI = "https://api.dev.ev.pebble.tech";
} else if (env === "production") {
  DashboardAPI = "https://api.ev.pebble.tech";
} else if (env === "staging") {
  DashboardAPI = "https://api.staging.ev.pebble.tech";
} else if (env === "local") {
  DashboardAPI = "http://localhost:3002";
}

const config = {
  API: {
    registration: {
      baseUrl: DashboardAPI,
      business: {
        postBusinessDetails: `https://api.dev.ev.pebble.tech/registration/user`,
        getBusinessUserDetails: `https://api.dev.ev.pebble.tech/registration/business-user-details/:userEmail`,
        confirmEmail: `https://api.dev.ev.pebble.tech/registration/enable-system-user`,
        getProductDetails: `https://api.dev.ev.pebble.tech/registration/products`,
        getDiscountCode: `https://api.dev.ev.pebble.tech/payment/validate-promo-code/:promoName`,
        createPassword: `https://api.dev.ev.pebble.tech/registration/password`,
        checkEmail: `https://api.dev.ev.pebble.tech/registration/check-email-address`,
      },
      payment: {
        postPaymentIntent: `https://api.dev.ev.pebble.tech/payment/create-intent`,
        postPaymentSession: `https://api.dev.ev.pebble.tech/payment/create-checkout-session`,
        getPaymentInfo: `https://api.dev.ev.pebble.tech/payment/order?email=:systemUserEmail`,
      },
    },
  },
};

export default config;
