import { FC } from "react";

import PaymentDetailsForm from "../../Components/Forms/PaymentDetails/PaymentDetailsForm";
import Payment from "../../Components/ProgressBar/Payment/Payment";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

import ErrorModal from "../../Components/Modals/ErrorModal/ErrorModal";

const PaymentDetailsPage: FC = () => {
  // const monthlyTotalAct = 0.99 * storeNumber;
  // const termTotalAct = monthlyTotalAct * 12;

  // const store = {
  //   businessEmail: details.userEmail,
  //   amount: termTotalAct,
  // };

  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   postPaymentIntent.mutate(store, {
  //     onSuccess: (response) => {
  //       setClientSecret(response.clientSecret);
  //     },
  //     onError: () => {
  //       setIsErrorOpen(true);
  //       setErrorMessage(
  //         "There was a problem connecting to Stripe, please reload the page and try again!"
  //       );
  //     },
  //   });
  // }, []);

  return (
    <>
      {/* Progress Bar */}
      <Payment />
      {/* Progress Bar */}

      <SectionHeader>Payment Details</SectionHeader>

      <PaymentDetailsForm />

      <ErrorModal />
    </>
  );
};

export default PaymentDetailsPage;
