import { FC } from "react";
import BusinessDetailsForm from "../../Components/Forms/BusinessDetails/BusinessDetailsForm";

import YourBusiness from "../../Components/ProgressBar/YourBusiness/YourBusiness";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

const BusinessDetailsPage: FC = () => {
  return (
    <>
      {/* Progress Bar */}
      <YourBusiness />
      {/* Progress Bar */}

      <SectionHeader>Business Details</SectionHeader>

      <BusinessDetailsForm />
    </>
  );
};

export default BusinessDetailsPage;
