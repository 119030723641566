import { FC, useState } from "react";
import { Button, Modal } from "antd";
import { useGlobalContext } from "../../../store/pageStore/pageStore";
import styles from "./DiscountModal.module.scss";

interface IDiscountModalProps {
  data: any;
}

const DiscountModal: FC<IDiscountModalProps> = ({ data }) => {
  const { setDiscountFetch, details, setDetails, setFreeAccount } =
    useGlobalContext();

  const [isDiscModalOpen, setIsDiscModalOpen] = useState<boolean>(true);

  const modalText = (
    <div>
      <p>Discount Applied!</p>
    </div>
  );

  const handleOk = async () => {
    setDetails({
      ...details,
      promoCodeId: data?.id,
      percentOff: data?.percentOff,
    });

    if (data?.percentOff === 100) {
      setFreeAccount(true);
    }

    setIsDiscModalOpen(false);
    setDiscountFetch(false);
  };

  return (
    <>
      <Modal
        title={<h3 className={styles.title}>Success!</h3>}
        open={isDiscModalOpen}
        centered
        footer={null}
        onCancel={handleOk}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <section className={styles.modalContent_text}>{modalText}</section>

            <div className={styles.modalFooter}>
              <Button
                style={{
                  minWidth: "110px",
                  fontSize: "0.9rem",
                  padding: "6px",
                }}
                onClick={handleOk}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DiscountModal;
