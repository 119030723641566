import { Spin } from "antd";
import { FC, useEffect } from "react";
import ErrorModal from "../../Components/Modals/ErrorModal/ErrorModal";
import PlanOptions from "../../Components/PlanOptions/PlanOptions";
import PlanSelect from "../../Components/PlanSelect/PlanSelect";
import ChoosePlan from "../../Components/ProgressBar/ChoosePlan/ChoosePlan";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import { useGetProductDetails } from "../../hooks/postHooks";
import { useGlobalContext } from "../../store/pageStore/pageStore";

import styles from "./ChoosePlanPage.module.scss";

const ChoosePlanPage: FC = () => {
  const { setProductDetails } = useGlobalContext();
  const { data, isLoading, isError } = useGetProductDetails();

  localStorage.clear();

  useEffect(() => {
    setProductDetails(data);
  }, [data]);

  if (isError) {
    <ErrorModal />;
  }

  return (
    <>
      {data && (
        <>
          {/* Progress Bar */}
          <ChoosePlan />
          {/* Progress Bar */}

          <SectionHeader>Choose the Right Plan for Your Company</SectionHeader>

          <PlanSelect />

          <PlanOptions />
        </>
      )}

      {isLoading && (
        <div className={styles.spinner}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default ChoosePlanPage;
