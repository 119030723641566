import { FC, ReactNode } from "react";

import styles from "./SectionHeader.module.scss";

type SectionProps = {
  children: ReactNode;
};

const SectionHeader: FC<SectionProps> = ({ children }) => {
  return (
    <header className={styles.sectionHeader}>
      <p>{children}</p>
    </header>
  );
};

export default SectionHeader;
