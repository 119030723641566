import { FC } from "react";

import styles from "./LoginDetailsConfirm.module.scss";

const LoginDetailsConfirm: FC = () => {
  return (
    <>
      <p className={styles.title}>
        Please check your email and click on the link to confirm your account.
      </p>
      <p className={styles.title}>
        Check your junk folder if you have been waiting for more than a few
        minutes.
      </p>
    </>
  );
};

export default LoginDetailsConfirm;
