import { FC, useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import { useGlobalContext } from "../../../store/pageStore/pageStore";
import styles from "./Activate.module.scss";

const Activate: FC = () => {
  const { details, setDetails, productDetails } = useGlobalContext();
  const [style, setStyle] = useState(styles.select);

  useEffect(() => {
    if (details.userPlan === "Activate") {
      setStyle(styles.selectClicked);
    } else {
      setStyle(styles.select);
    }
  }, [details]);

  const productActivate = productDetails?.[0];
  const totalPrice = +productActivate?.price;
  const monthlyAmount = totalPrice / 12;
  const monthlyPrice = monthlyAmount?.toFixed(2);

  const monthlyPriceArray = monthlyPrice?.split("");

  const onClick = async () => {
    setDetails({
      ...details,
      userPlan: "Activate",
    });
  };

  return (
    <>
      <div className={style} onClick={onClick}>
        <header className={styles.select_header}>
          {productActivate?.name.toUpperCase()}
        </header>
        <div className={styles.select_background}>
          <div className={styles.money}>
            <span className={styles.money_one}>$</span>
            <span className={styles.money_two}>
              {monthlyPriceArray[0]}
              {monthlyPriceArray[1]}
            </span>
            <span className={styles.money_three}>
              {monthlyPriceArray[2]}
              {monthlyPriceArray[3]}
            </span>
          </div>
          <p className={styles.select_lineOne}>per store per month</p>
          <p className={styles.select_lineTwo}>
            Billed at ${productActivate?.price}/year
          </p>
          <div className={styles.select_borderLine}></div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>
              {productActivate?.specs[0]}
            </p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>
              {productActivate?.specs[1]}
            </p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>
              {productActivate?.specs[2]}
            </p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>
              {productActivate?.specs[3]}
            </p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>
              {productActivate?.specs[4]}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activate;
