import { FC } from "react";

import styles from "../ProgressBar.module.scss";

const ChoosePlan: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.circleSelected}>
        <div className={styles.text}>Choose Plan</div>
      </div>

      <div className={styles.circle}>
        <div className={styles.text}>Your Business</div>
      </div>

      <div className={styles.circle}>
        <div className={styles.text}>User Details</div>
      </div>

      <div className={styles.circle}>
        <div className={styles.text}>
          <span>Payment</span>
        </div>
      </div>

      <div className={styles.circle}>
        <div className={styles.text}>Confirm</div>
      </div>
    </div>
  );
};

export default ChoosePlan;
