import { Button, Checkbox, Form, Input } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { FC, useState } from "react";
import { useCreateBusinessUser } from "../../../hooks/postHooks";
import { useGlobalContext } from "../../../store/pageStore/pageStore";
import styles from "./UserDetailsForm.module.scss";
import ErrorModal from "../../Modals/ErrorModal/ErrorModal";

const UserDetailsForm: FC = () => {
  const {
    details,
    setDetails,
    storeNumber,
    freeAccount,
    setPage,
    setErrorMessage,
    setErrorModalOpen,
    stripePriceId,
    promoCodeId,
  } = useGlobalContext();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const createBusinessUser = useCreateBusinessUser();

  const [form] = Form.useForm();

  const onEmailChange = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({
      userEmail: details.companyEmail,
    });

    setDetails({ ...details, userEmail: details.companyEmail });
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const onFinish = () => {
    form.getFieldsValue(true);
    const store = {
      businessPlan: details.userPlan,
      numberOfStores: parseInt(storeNumber),
      companyName: details.companyName,
      userEmailAddress: details.userEmail,
      businessEmailAddress: details.companyEmail,
      firstName: details.firstName,
      lastName: details.lastName,
      userPhoneNumber: details.userPhoneNumber,
      businessPhoneNumber: details.companyPhoneNumber,
      addressLine1: details.addressLineOne,
      addressLineTwo: details.addressLineTwo,
      businessWebsite: details.businessWebsite,
      city: details.city,
      state: details.state,
      postcode: details.zipCode,
      active: false,
      paymentStatus: "new_account",
      freeAccount: freeAccount,
      stripePriceId: stripePriceId,
      promoCodeId: promoCodeId,
    };

    setConfirmLoading(true);
    setTimeout(() => {
      createBusinessUser.mutate(store, {
        onSuccess: () => {
          setConfirmLoading(false);
          setPage("loginDetailsConfirm");
        },
        onError: async (error: any) => {
          if (error.response.data.message[0]) {
            setErrorMessage(`${capitalize(error.response.data.message[0])}!`);
          } else {
            setErrorMessage("There was a problem! Please try again!");
          }
          setConfirmLoading(false);
          setErrorModalOpen(true);
        },
      });
    }, 1000);
  };
  return (
    <>
      <div className={styles.businessForm}>
        <p className={styles.subHeaderOne}>
          Please Provide the Following Information:
        </p>
        <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          className={styles.formArea}
          initialValues={details}>
          <div className={styles.formAreaBase}>
            <div className={styles.formAreaLeft}>
              <p className={styles.title}>First Name</p>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First Name is required",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      firstName: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>Your Email Address</p>
              <Form.Item valuePropName="checked">
                <Checkbox className={styles.checkBox} onChange={onEmailChange}>
                  <p className={styles.checkBoxText}>
                    Use Business Email Address?
                  </p>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="userEmail"
                rules={[
                  {
                    required: true,
                    message: "User Email is required",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      userEmail: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>

            <div className={styles.formAreaRight}>
              <p className={styles.title}>Last Name</p>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Last Name is required",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      lastName: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>
                Cell Number {""}
                <span className={styles.title_text}>
                  (Recieve campaign/offer alerts & reminders!)
                </span>
              </p>
              {/* <Form.Item valuePropName="checked">
                <Checkbox className={styles.checkBox} onChange={onPhoneChange}>
                  <p className={styles.checkBoxText}>Use Business Number?</p>
                </Checkbox>
              </Form.Item> */}

              <Form.Item
                name="userPhoneNumber"
                rules={[
                  {
                    required: true,
                    message: "User Phone Number is required",
                  },
                ]}>
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      userPhoneNumber: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>

          <p className={styles.subHeaderTwo}>
            Your email address will be used to access your Pebble Dashboard. You
            will set up your password in the next step. Please ensure all
            business information is accurate before proceeding!
          </p>

          <div className={styles.button}>
            <Button loading={confirmLoading} htmlType="submit">
              Create Account!
            </Button>
          </div>
        </Form>
        <ErrorModal />
      </div>
    </>
  );
};

export default UserDetailsForm;
