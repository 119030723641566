import { FC } from "react";
import { useGetDiscountCode } from "../../hooks/postHooks";
import DiscountErrorModal from "../Modals/DiscountErrorModal/DiscountErrorModal";

import DiscountModal from "../Modals/DiscountModal/DiscountModal";

interface IDiscountCodeProps {
  promoName: any;
}

const DiscountCode: FC<IDiscountCodeProps> = ({ promoName }) => {
  //   ALWAYS25OFF
  //   FREEPRODUCT
  const { data, isError } = useGetDiscountCode(promoName);

  return (
    <>
      {data && <DiscountModal data={data} />}

      {isError && <DiscountErrorModal />}
    </>
  );
};

export default DiscountCode;
