import { PaymentElement } from "@stripe/react-stripe-js";
import { Button, Form, Input } from "antd";
import { FC } from "react";
import { useGlobalContext } from "../../../store/pageStore/pageStore";
import ErrorModal from "../../Modals/ErrorModal/ErrorModal";
import styles from "./PaymentDetailsForm.module.scss";

const PaymentDetailsForm: FC = () => {
  const { storeNumber, details } = useGlobalContext();

  const [form] = Form.useForm();

  const monthlyTotalAct = 0.99 * storeNumber;
  const termTotalAct = monthlyTotalAct * 12;

  const monthlyTotalPro = 19.99 * storeNumber;
  const termTotalPro = monthlyTotalPro * 12;

  const monthlyTotalCon = 49.99 * storeNumber;
  const termTotalCon = monthlyTotalCon * 12;

  const appearance = {
    layout: "tabs",
  } as const;

  return (
    <>
      <div className={styles.businessForm}>
        <p className={styles.selectedPlan}>
          Selected Plan:
          <span className={styles.selectedPlan_plan}>
            {" "}
            {details.userPlan.toUpperCase()}
          </span>
        </p>

        <Form form={form} scrollToFirstError className={styles.formArea}>
          <div className={styles.formAreaLeft}>
            <p className={styles.title}>Card Details</p>
            <span style={{ paddingBottom: "20px" }}></span>
            <PaymentElement options={appearance} />
            <div className={styles.totalBar}>
              <p className={styles.totalBar_total}>Total</p>
              <p className={styles.totalBar_amount}>
                {details.userPlan === "activate" &&
                  `$${termTotalAct.toFixed(2)}`}
                {details.userPlan === "pro" && `$${termTotalPro.toFixed(2)}`}
                {details.userPlan === "concierge" &&
                  `$${termTotalCon.toFixed(2)}`}
              </p>
            </div>

            <div className={styles.submitPurchase}>
              <Button
                style={{ width: "100%", height: "55px", fontSize: "1.6rem" }}
                htmlType="submit">
                SUBMIT PURCHASE
              </Button>
            </div>
          </div>

          <div className={styles.formAreaRight}>
            <p className={styles.title}>Order Summary</p>
            <div className={styles.orderSummary}>
              <div className={styles.orderSummaryList}>
                <p className={styles.orderSummaryList_title}>Selected Plan:</p>
                <p className={styles.orderSummaryList_content}>
                  {details.userPlan.toUpperCase()}
                </p>
              </div>
              <div className={styles.orderSummaryList}>
                <p className={styles.orderSummaryList_title}># of Locations:</p>
                <p className={styles.orderSummaryList_content}>{storeNumber}</p>
              </div>
              <div className={styles.orderSummaryList}>
                <p className={styles.orderSummaryList_title}>Monthly Cost:</p>
                <p className={styles.orderSummaryList_content}>
                  {details.userPlan === "activate" &&
                    `$${monthlyTotalAct.toFixed(2)}`}
                  {details.userPlan === "pro" &&
                    `$${monthlyTotalPro.toFixed(2)}`}
                  {details.userPlan === "concierge" &&
                    `$${monthlyTotalCon.toFixed(2)}`}
                </p>
              </div>
              <div className={styles.orderSummaryList}>
                <p className={styles.orderSummaryList_title}>Contract Term:</p>
                <p className={styles.orderSummaryList_content}>12 months</p>
              </div>
              <div className={styles.orderSummaryList}>
                <p className={styles.orderSummaryList_title}>Discount:</p>
                <p className={styles.orderSummaryList_content}>None applied</p>
              </div>
            </div>

            <p className={styles.title}>Discount Code</p>
            <div className={styles.discountCode}>
              <div className={styles.discountCode_input}>
                <Form.Item
                  style={{ width: "100%" }}
                  name="discount"
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input placeholder="Optional" />
                </Form.Item>
              </div>

              <Button style={{ height: "40px", minWidth: "80px" }}>
                APPLY
              </Button>
            </div>

            <div className={styles.terms}>
              <p className={styles.terms_text}>
                Your privacy is super important to us - we will only use your
                information as described in our{" "}
                <span className={styles.terms_highlight}>Terms of Use</span> and
                <span className={styles.terms_highlight}> Privacy Policy</span>.
              </p>
            </div>
          </div>
        </Form>
      </div>
      <ErrorModal />
    </>
  );
};

export default PaymentDetailsForm;
