
import axios from 'axios';
import config from '../config/reg.config';
import { IGetPaymentInfo, IPostPaymentIntent, IPostPaymentSession } from '../types/paymentTypes';
import {  } from '../types/userDetails';

// --------------- Queries ------------- //

export const getPaymentInfo = async (userPayEmail: string | any) => {
  const url = config.API.registration.payment.getPaymentInfo.replace(':systemUserEmail', userPayEmail)
  const { data }: {data: IGetPaymentInfo} = await axios.get(url);

  return data;
};


// --------------- Mutations ------------- //

export const postPaymentIntent = async (data: IPostPaymentIntent) => {
    const url = config.API.registration.payment.postPaymentIntent;

    const { data: response } = await axios.post(url, data);

    return response

  };

export const postPaymentSession = async (data: IPostPaymentSession) => {
    const url = config.API.registration.payment.postPaymentSession;

    const { data: response } = await axios.post(url, data);

    return response

  };



