import { FC } from "react";
import { Button, Modal } from "antd";

import styles from "./ErrorModal.module.scss";
import { useGlobalContext } from "../../../store/pageStore/pageStore";

const ErrorModal: FC = () => {
  const { errorMessage, setDiscountFetch, errorModalOpen, setErrorModalOpen } =
    useGlobalContext();

  const handleErrorCancel = () => {
    setErrorModalOpen(false);
    setDiscountFetch(false);
  };
  const handleErrorCanelClick = () => {
    setErrorModalOpen(false);
    setDiscountFetch(false);
  };

  return (
    <>
      <Modal
        title={<h3 className={styles.title}>Oops! Something Went Wrong!</h3>}
        open={errorModalOpen}
        centered
        footer={null}
        onCancel={handleErrorCancel}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <section className={styles.modalContent_text}>
              <p>{errorMessage}</p>
            </section>

            <div className={styles.modalFooter}>
              <Button
                style={{
                  minWidth: "110px",
                  fontSize: "0.9rem",
                  padding: "6px",
                }}
                onClick={handleErrorCanelClick}>
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ErrorModal;
