import { FC } from "react";
import UserDetailsForm from "../../Components/Forms/UserDetails/UserDetailsForm";

import UserSetup from "../../Components/ProgressBar/UserSetup/UserSetup";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";

const UserSetupPage: FC = () => {
  return (
    <>
      {/* Progress Bar */}
      <UserSetup />
      {/* Progress Bar */}
      <SectionHeader>Your Contact Information</SectionHeader>
      <UserDetailsForm />
    </>
  );
};

export default UserSetupPage;
