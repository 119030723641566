import { FC } from "react";

import Activate from "./Activate/Activate";
import Concierge from "./Concierge/Concierge";
import styles from "./PlanSelect.module.scss";
import Pro from "./Pro/Pro";

const PlanSelect: FC = () => {
  return (
    <>
      <div className={styles.container}>
        <Activate />
        <Pro />
        <Concierge />
      </div>
    </>
  );
};

export default PlanSelect;
