import { FC } from "react";
import { TiTick } from "react-icons/ti";
import { useGlobalContext } from "../../../store/pageStore/pageStore";
import styles from "./Concierge.module.scss";

const Concierge: FC = () => {
  const { productDetails } = useGlobalContext();
  // const [style, setStyle] = useState(styles.select);

  // useEffect(() => {
  //   if (userPlan === "Concierge") {
  //     setStyle(styles.selectClicked);
  //   } else {
  //     setStyle(styles.select);
  //   }
  // }, [userPlan]);

  const product = productDetails?.[2];
  const totalPrice = +product?.price;
  const monthlyAmount = totalPrice / 12;
  const monthlyPrice = monthlyAmount?.toFixed(2);

  const monthlyPriceArray = monthlyPrice?.split("");

  const onClick = () => {
    console.log("Concierge currently disabled");
    // setUserPlan("Concierge");
  };
  return (
    <>
      <div className={styles.select} onClick={onClick}>
        <header className={styles.select_header}>
          {product?.name.toUpperCase()}
        </header>
        <div className={styles.select_background}>
          <div className={styles.money}>
            <span className={styles.money_one}>$</span>
            <span className={styles.money_two}>
              {monthlyPriceArray[0]}
              {monthlyPriceArray[1]}
            </span>
            <span className={styles.money_three}></span>
          </div>
          <p className={styles.select_lineOne}>per store per month</p>
          <p className={styles.select_lineTwo}>
            Billed at ${product?.price}/year
          </p>
          <div className={styles.select_borderLine}></div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>{product?.specs[0]}</p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>{product?.specs[1]}</p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>{product?.specs[2]}</p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>{product?.specs[3]}</p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>{product?.specs[4]}</p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>{product?.specs[5]}</p>
          </div>
          <div className={styles.bulletPoints}>
            <span className={styles.bulletPoints_tick}>
              <TiTick size={18} />
            </span>
            <p className={styles.bulletPoints_text}>{product?.specs[6]}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Concierge;
