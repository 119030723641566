import { Button, Form, Input, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmailConfirmed from "../../Components/ProgressBar/EmailConfirmed/EmailConfirmed";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import {
  useGetPaymentInfo,
  usePostPaymentSession,
} from "../../hooks/paymentHooks";
import {
  useConfirmBusinessUser,
  useCreatePassword,
  useGetBusinessUserDetails,
} from "../../hooks/postHooks";
import { useGlobalContext } from "../../store/pageStore/pageStore";
import styles from "./EmailConfirmedPage.module.scss";
import ErrorModal from "../../Components/Modals/ErrorModal/ErrorModal";

const EmailConfirmedPage: FC = () => {
  const {
    details,
    setDetails,
    setStoreNumber,
    setErrorModalOpen,
    setErrorMessage,
    setFreeAccount,
    freeAccount,
  } = useGlobalContext();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [spinActive, setSpinActive] = useState<boolean>(true);
  const [errorMode, setErrorMode] = useState<boolean>();
  const [passwordMade, setPasswordMade] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const confirmEmail = useConfirmBusinessUser();
  const createPassword = useCreatePassword();
  const postPaymentSession = usePostPaymentSession();

  const userEmail = searchParams.get("email");
  const token = searchParams.get("token");

  const userPayEmail = encodeURIComponent(userEmail as string);

  const { data } = useGetBusinessUserDetails(userEmail);
  const { data: paymentInfo } = useGetPaymentInfo(userPayEmail, passwordMade);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    if (data?.paymentStatus === "free account") {
      setFreeAccount(true);
    }
  }, [data]);

  useEffect(() => {
    if (token && userEmail) {
      const confirmEmailstore = {
        emailAddress: userEmail,
        confirmationToken: token,
      };
      confirmEmail.mutate(confirmEmailstore, {
        onSuccess: async () => {
          setSpinActive(false);
          setErrorMode(false);
        },
        onError: async () => {
          setSpinActive(false);
          setErrorMode(true);
        },
      });
    }
  }, []);

  const onClick = async () => {
    setConfirmLoading(true);
    setDetails({
      ...details,
      userEmail: userEmail,
      companyName: data?.companyName,
      companyEmail: data?.emailAddress,
      companyPhoneNumber: data?.phoneNumber,
      userPlan: data?.businessPlan,
    });
    setStoreNumber(data?.numberOfStores);

    const postSessionStore = {
      stripeCustomerId: data?.stripeCustomerId,
      priceId: paymentInfo?.stripePriceId,
      quantity: data?.numberOfStores,
      promoCodeId: paymentInfo?.stripePromoCodeId,
    };

    setTimeout(() => {
      postPaymentSession.mutate(postSessionStore, {
        onSuccess: (response) => {
          window.location.href = response.url;
          setConfirmLoading(false);
        },
        onError: (error) => {
          setErrorMessage("Something went wrong when trying to access Stripe!");
          setErrorModalOpen(true);
          console.log(error);
          setConfirmLoading(false);
        },
      });
    }, 600);
  };

  const onPasswordFinish = () => {
    setConfirmLoading(true);
    form.getFieldsValue(true);

    const createPasswordStore = {
      emailAddress: userEmail,
      password: details.password,
    };

    setTimeout(() => {
      createPassword.mutate(createPasswordStore, {
        onSuccess: async (response) => {
          localStorage.setItem("cc_username", userEmail as string);
          localStorage.setItem("cc_client_token", response.data.token);
          localStorage.setItem(
            "cc_client_refresh_token",
            response.data.refreshToken
          );
          localStorage.setItem("user_preference_theme", "light");
          setConfirmLoading(false);
          if (freeAccount === true) {
            navigate("/account-confirmed");
          } else if (freeAccount === false) {
            setPasswordMade(true);
          }
        },
        onError: (error) => {
          setErrorMessage("Something went wrong! Please try again!");
          setErrorModalOpen(true);
          console.log(error);
          setConfirmLoading(false);
        },
      });
    }, 600);
  };

  return (
    <>
      {/* Progress Bar */}
      <EmailConfirmed />
      {/* Progress Bar */}

      {spinActive && (
        <div className={styles.spinner}>
          <Spin size="large" />
        </div>
      )}

      {errorMode && (
        <>
          <SectionHeader>
            <span className={styles.errorTitle}>
              Oops! Something went wrong!
            </span>
          </SectionHeader>
          <p className={styles.title}>Sorry, this link is invalid!</p>
          <p className={styles.title}>
            Please return to the registration form and try again.
          </p>
        </>
      )}

      {!spinActive && !errorMode && !passwordMade && (
        <>
          <SectionHeader>Confirmed!</SectionHeader>
          <p className={styles.title}>
            Your email address has now been confirmed!
          </p>
          <p className={styles.subHeaderTwo}>
            Now choose a safe and memorable password. This, in combination with
            the email address you provided will become your login credentials
            for accessing your Pebble dashboard.
          </p>
          <Form
            form={form}
            onFinish={onPasswordFinish}
            scrollToFirstError
            className={styles.formArea}>
            <div className={styles.formAreaBase}>
              <div className={styles.formAreaLeft}>
                <p className={styles.title}>Password</p>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },

                    {
                      pattern: new RegExp(
                        /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{10,}$/
                      ),
                      message:
                        "Password must be a minimum of 10 characters with at least 1 number and one special character (!@#$%^&*).",
                    },
                  ]}
                  hasFeedback>
                  <Input.Password
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        password: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </div>

              <div className={styles.formAreaRight}>
                <p className={styles.title}>Confirm Password</p>
                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords do not match!")
                        );
                      },
                    }),
                  ]}>
                  <Input.Password />
                </Form.Item>
              </div>
            </div>
            <div className={styles.button}>
              <Button htmlType="submit" loading={confirmLoading}>
                Create Password!
              </Button>
            </div>
          </Form>
        </>
      )}
      {passwordMade && (
        <>
          <SectionHeader>Congratulations!</SectionHeader>
          <p className={styles.passConfirmedTitle}>
            Your password has been created and your user details have been
            confirmed! Click below to proceed to payment!
          </p>
          <div className={styles.button}>
            <Button onClick={onClick} loading={confirmLoading}>
              Continue
            </Button>
          </div>
        </>
      )}

      <ErrorModal />
    </>
  );
};

export default EmailConfirmedPage;
